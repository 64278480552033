const requiredConfigKeys: string[] = [
    'REACT_APP_API_URL',
    'REACT_APP_CDN_URL',
];

const missingKeys: string[] = requiredConfigKeys.filter((key) => !process.env[key]);

if (missingKeys.length) {
    throw new Error(`Missing config keys: ${missingKeys.join(', ')}`);
}

export default {
    apiUrl: process.env.REACT_APP_API_URL as string,
    cdnUrl: process.env.REACT_APP_CDN_URL as string,
}
