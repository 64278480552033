import React from 'react';
import {IEventScheduleDayActivity} from "../../core/getEventSchedule";
import {Grid, Typography} from "@material-ui/core";
import moment from "moment";
import {ScheduleImage} from "./ScheduleImage";

export const DayActivityContentHeader = (props: {activity: IEventScheduleDayActivity}) => {
    const parseTime = (timestamp: number) => moment(timestamp).format('HH:mm');

    const hours: string = props.activity.timeStart !== props.activity.timeEnd
        ? `${parseTime(props.activity.timeStart)} - ${parseTime(props.activity.timeEnd)}`
        : parseTime(props.activity.timeStart);

    return (
        <Grid container>
            <Grid
                item
                xs={2}
                style={{
                    padding: 5,
                    paddingBottom: 20,
                }}
            >
                <ScheduleImage
                    id={props.activity.icon}
                    style={{
                        width: '100%',
                    }}
                />
            </Grid>
            <Grid
                item
                xs={10}
                style={{
                    paddingLeft: 10,
                }}
            >
                <Typography>{props.activity.name}</Typography>
                <Typography>{hours}</Typography>
            </Grid>
        </Grid>
    );
};
