import React from 'react';
import './App.css';
import AppIcon from './media/app-icon.png';
import {extractTokenFromUrl} from "./core/extractTokenFromUrl";
import {getEventSchedule, IEventSchedule} from "./core/getEventSchedule";
import {EventNotActive} from "./views/EventNotActive";
import {EventSchedule} from "./views/schedule/EventSchedule";
import {Grid} from "@material-ui/core";

interface IAppState {
  eventSchedule: IEventSchedule | null;
  fetching: boolean;
}

function App() {
  const token: string | undefined = extractTokenFromUrl();

  const [ state, setState ] = React.useState<IAppState>({
    eventSchedule: null,
    fetching: !!token,
  });

  if (token && state.fetching) {
    getEventSchedule(token)
        .then((eventSchedule) => setState({
            ...state,
            eventSchedule,
            fetching: false,
        }))
        .catch(() => setState({
            ...state,
            fetching: false,
        }));
  }

  return (
      <Grid
          container
          justify={'center'}
      >
          <Grid
              item
              style={{
                  paddingTop: 20,
              }}
          >
              <img
                  src={AppIcon}
                  alt={'Gran Turismo Lion'}
                  style={{
                      width: '80px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                  }}
              />
          </Grid>
          {state.fetching ? (
              <div>
                  {/*fetching*/}
              </div>
          ) : (
              state.eventSchedule ? <EventSchedule eventSchedule={state.eventSchedule} /> : <EventNotActive />
          )}
      </Grid>
  );
}

export default App;
