import React from 'react';
import {Button, Grid} from "@material-ui/core";
import GooglePinWithArrow from '../../media/google-pin-with-arrow-x4.png';

export const DayActivityButton = () => {
    return (
        <Grid
            container
            item
            xs={2}
            style={{
                backgroundColor: '#fafafa',
                borderRadius: 20,
            } as any}
        >
            <Button>
                <img
                    src={GooglePinWithArrow}
                    style={{
                        width: '60%',
                    }}
                    alt={'Open in Google Maps icon'}
                />
            </Button>
        </Grid>
    );
};
