import React from 'react';
import {getImageUrl} from "../../core/getImageUrl";

interface IScheduleImageProps extends React.ImgHTMLAttributes<any> {
    id: string;
}

export const ScheduleImage = ({id, ...otherProps}: IScheduleImageProps) => {
    return (
        <img
            src={getImageUrl(id)}
            alt={'Main day illustration'}
            {...otherProps}
        />
    );
};
