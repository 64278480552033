import React from 'react';
import {IEventScheduleDayActivity} from "../../core/getEventSchedule";
import {Grid, Typography} from "@material-ui/core";

export const DayActivityContentBody = (props: {activity: IEventScheduleDayActivity}) => {
    return (
        <Grid>
            <Typography>{props.activity.description}</Typography>
        </Grid>
    );
};
