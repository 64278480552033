import React from 'react';
import {IEventScheduleDayActivity} from "../../core/getEventSchedule";
import {Grid} from "@material-ui/core";
import {DayActivityContentHeader} from "./DayActivityContentHeader";
import {DayActivityContentBody} from "./DayActivityContentBody";

export const DayActivityContent = (props: {activity: IEventScheduleDayActivity, xs: 10 | 12}) => {
    return (
        <Grid
            item
            xs={props.xs}
            style={{
                padding: 20,
            }}
        >
            <DayActivityContentHeader activity={props.activity} />
            <DayActivityContentBody activity={props.activity} />
        </Grid>
    );
};
