import axios, { AxiosResponse } from 'axios';
import config from "./config";

export interface IEventSchedule {
    eventName: string;
    days: IEventScheduleDay[];
}

export interface IEventScheduleDay {
    date: string;
    description: string;
    image: string | null;
    name: string;
    activities: IEventScheduleDayActivity[];
}

export interface IEventScheduleDayActivity {
    description: string;
    googleMapUrl: string;
    icon: string;
    name: string;
    timeStart: number;
    timeEnd: number;
}

export const getEventSchedule = async (token: string): Promise<IEventSchedule> => {
    const url: string = `${config.apiUrl}/public-schedule/${token}`;
    const result: AxiosResponse<IEventSchedule> = await axios.get(url);
    return result.data;
};
