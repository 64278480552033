import React from 'react';
import {Grid} from "@material-ui/core";
import {IEventScheduleDayActivity} from "../../core/getEventSchedule";
import {DayActivityContent} from "./DayActivityContent";
import {DayActivityButton} from "./DayActivityButton";

export const DayActivity = (props: {activity: IEventScheduleDayActivity}) => {
    const isGoogleMapUrlDefined: boolean = !!props.activity.googleMapUrl;

    if (!isGoogleMapUrlDefined) {
        return (
            <Grid
                container
                style={{
                    borderBottom: '1px solid #dddddd',
                    borderRadius: 20,
                    backgroundColor: '#ffffff',
                    marginBottom: 10,
                }}
            >
                <DayActivityContent activity={props.activity} xs={12} />
            </Grid>
        );
    }

    return (
        <a
            href={props.activity.googleMapUrl}
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
            <Grid
                container
                style={{
                    borderBottom: '1px solid #dddddd',
                    borderRadius: 20,
                    backgroundColor: '#ffffff',
                    marginBottom: 10,
                    cursor: 'pointer',
                }}
            >
                <DayActivityContent activity={props.activity} xs={10} />
                <DayActivityButton />
            </Grid>
        </a>
    );
};
