import React from 'react';

export const EventNotActive = () => {
    return (
        <div style={{
            textAlign: 'center',
            width: '100%',
        }}>
            Page not found
        </div>
    );
};
