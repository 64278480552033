import React from 'react';
import moment from 'moment';
import {IEventScheduleDay} from "../../core/getEventSchedule";
import {Grid, Typography} from "@material-ui/core";
import {DayActivity} from "./DayActivity";
import {getImageUrl} from "../../core/getImageUrl";

export const ScheduleDay = (props: {day: IEventScheduleDay}) => {
    const formatDate = (date: string) => moment(date).format('dddd, D MMMM');

    return (
        <React.Fragment>
            <Grid
                container
                style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 20,
                }}
            >
                <Grid
                    item
                    container
                    xs={12}
                    style={{
                        borderRadius: 20,
                    }}>
                    <Grid
                        alignItems={'flex-end'}
                        container
                        item
                        xs={12}
                        style={{
                            background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${getImageUrl(props.day.image as string)})`,
                            // backgroundImage: `url(${getImageUrl(props.day.image as string)})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '25vh',
                            borderRadius: 20,
                            padding: 20,
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {props.day.name}
                            </Typography>
                            <Typography variant={'h6'}>
                                {formatDate(props.day.date)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    {props.day.image ? (*/}
                    {/*        <ScheduleImage*/}
                    {/*            id={props.day.image}*/}
                    {/*            style={{*/}
                    {/*                width: '100%',*/}
                    {/*                borderRadius: 30,*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    ) : null}*/}
                    {/*</Grid>*/}
                    <Grid item container xs={12} style={{padding: 20}}>
                        <Grid item xs={12}>
                            <Typography variant={'body1'}>
                                {props.day.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    paddingTop: 10
                }}
            >
                <Grid item xs={12}>
                    {props.day.activities.map((activity, index) => (
                        <DayActivity key={`activity_${index}`} activity={activity} />
                    ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
