import React from 'react';
import {IEventSchedule} from "../../core/getEventSchedule";
import {Grid, Typography} from "@material-ui/core";
import {ScheduleDay} from "./ScheduleDay";

export const EventSchedule = (props: {eventSchedule: IEventSchedule}) => {
    return (
        <Grid
            container
        >
            <Grid
                item
                style={{
                    backgroundColor: '#eeeeee',
                    padding: 20,
                }}
                xs={12}
            >
                <Typography
                    variant={'h4'}
                    align={'center'}
                    style={{
                        fontWeight: 'bold',
                    }}
                >{props.eventSchedule.eventName}</Typography>
            </Grid>
            {props.eventSchedule.days.map((day, index) => (
                <div
                    key={`day_${index}`}
                    style={{
                        maxWidth: 500,
                        padding: 10,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <ScheduleDay day={day} />
                </div>
            ))}
        </Grid>
    );
};
